import React from 'react';
import { graphql } from 'gatsby';
import DefaultTemplate from './defaultTemplate';
import { BlogTrailer } from '../components/utility/blogTrailer';
import { SocialMedia } from '../components/utility/socialMedia';
import { NewsletterForm } from '../components/utility/newsletterForm';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles((theme) => ({
  container: {
    marginTop: 'var(--size-3x)'
  }
}));
export default function BlogPost({
  data: { markdownRemark, allMarkdownRemark },
  pageContext: { header },
  ...props
}) {
  const classes = useStyles();
  return (
    <DefaultTemplate
      markdown={markdownRemark}
      header={header}
      image={markdownRemark.frontmatter.coverImage.publicURL}
      footer={
        <>
          <NewsletterForm
            url={props.location.pathname}
            popup={false}
            containerClass={classes.container}
          />
          <SocialMedia
            path={props.location.pathname}
            date={markdownRemark.frontmatter.date}
          />
          <BlogTrailer
            className="full-width"
            nodes={allMarkdownRemark.nodes}
            heading="Podobne artykuły"
          />
        </>
      }
      {...props}
    />
  );
}

export const pageQuery = graphql`
  query($slug: String!, $links: [String!]) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      ...ArticleQuery
      frontmatter {
        coverImage {
          publicURL
        }
      }
    }
    allMarkdownRemark(filter: { fields: { slug: { in: $links } } }) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          description
          title
          coverImage {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;
