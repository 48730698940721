import React from 'react';
import { createUseStyles } from 'react-jss';
import { BlogPostTrailer } from './blogPostTrailer';
import classNames from 'classnames';

const useStyles = createUseStyles((theme) => ({
  container: {},
  header: {
    margin: ['2rem', 'auto'],
    textAlign: 'center'
  },
  heading: {
    fontSize: 'var(--font-size-xxl)',
    fontWeight: 600
  },
  subHeading: {
    fontSize: 'var(--font-size-lg)',
    fontWeight: 500
  },
  greyBox: {
    paddingTop: 'var(--size-4x)',
    paddingBottom: 'var(--size-4x)',
    ...theme.minSidePadding,
    backgroundColor: theme.lightGrey
  },
  grid: {
    maxWidth: 'var(--layout-width)',
    margin: [[0, 'auto']],
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridTemplateRows: 'calc(min(1200px, 100vw - 40px) / 2)',
    gap: '2vmax'
  },
  [`@media (max-width: ${theme.breakPoints.xsMax})`]: {
    grid: {
      maxWidth: 400,
      gridTemplateColumns: '1fr',
      gridTemplateRows: 'repeat(3, min-content)'
    }
  }
}));

export const BlogTrailer = ({ nodes, heading, subheading, className }) => {
  const classes = useStyles();
  return (
    <section className={classNames(classes.container, className)}>
      <div className={classes.greyBox}>
        <header className={classes.header}>
          <h2 className={classes.heading}>{heading}</h2>
          {subheading && <p className={classes.subHeading}>{subheading}</p>}
        </header>
        <div className={classes.grid}>
          {nodes.map((n) => (
            <BlogPostTrailer
              key={n.fields.slug}
              {...n.frontmatter}
              {...n.fields}
            />
          ))}
        </div>
      </div>
    </section>
  );
};
